import Script from 'next/script';

export const AdriverScript = () => (
  <>
    <Script
      id="adriver"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
          window.adrCounterStorage = window.adrCounterStorage || []; window.adrCounterStorage.push(() => {
    new AdriverCounterJS({sid:228801, bt:62},{id:"",gid1:"",yid1:""})
    });
        `,
      }}
    />
  </>
);
